<template>
    <div>
        <CompModal ref="modal" :title="nameover + '网上家园建设情况总览'" :isFull="true" :toggle="toggle" @on-close="closeFn1()">
            <template>
                <Breadcrumb separator="<b class='demo-breadcrumb-separator'>=></b>" style="padding:10px 20px;">
                    <BreadcrumbItem>{{ nameList1 }}</BreadcrumbItem>
                    <BreadcrumbItem>{{ nameList2 }}</BreadcrumbItem>
                </Breadcrumb>
            </template>
            <div class="header">
                <Card style="width:320px" class="cards" v-if="regionType == '1'">
                    <div style="text-align:center">
                        <p>
                            总楼栋数：
                            <span>{{ dataList.buildingCount }}</span>
                        </p>
                        <p>{{ dataList.buildingSettledCount }}</p>
                        <p>入驻楼栋</p>
                    </div>
                </Card>
                <Card style="width:320px" class="cards">
                    <div style="text-align:center">
                        <p id="house">
                            <span>初始化户数：{{ dataList.initHouseNum }}</span>
                        </p>
                        <p>{{ dataList.houseSettledCount }}</p>
                        <p>入驻户数</p>
                    </div>
                </Card>
            </div>
            <div class="illustrated">
                <ul>
                    <li>图像说明:</li>
                    <li>
                        房号/门牌号:
                        <span class="white" ref="whiteFn"></span>
                        <div class="info">
                            <span>{{ dataList.unSettledCount }}</span>
                            <span>未入驻</span>
                        </div>
                    </li>
                    <li>
                        <span class="yellow"></span>
                        <div class="info">
                            <span>{{ dataList.houseSettledCount - dataList.haveContactHouseNum > 0 ? dataList.houseSettledCount - dataList.haveContactHouseNum : 0 || "0" }}</span>
                            <span>已入驻无户代表</span>
                        </div>
                    </li>
                    <li>
                        <span class="blue"></span>
                        <div class="info">
                            <span>{{ dataList.haveContactHouseNum }}</span>
                            <span>已入驻有户代表</span>
                        </div>
                    </li>
                </ul>
            </div>
            <div v-if="overviewtoggle" class="area">
                <div class="area-wrapper" v-for="item in dataList.tree" :key="item.id">
                    <span>{{ item.name }}</span>
                    <div class="wrapper-item">
                        <template v-if="item.child !== null">
                            <Card class="cards" v-for="childItem in item.child" :key="childItem.id">
                                <div @click="open(childItem.code, childItem.name)" :class="childItem.contact === '0' ? 'baise' : childItem.contact === '1' ? 'blue' : 'yellow'">
                                    <!--                    <p style="height: 30px">-->
                                    <!--                      <span>10号</span>-->
                                    <!--                    </p>-->
                                    <p>
                                        <span>{{ childItem.name }}</span>
                                    </p>
                                </div>
                            </Card>
                        </template>
                        <div v-if="item.child === null" style="width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;">暂无数据</div>
                    </div>
                </div>
                <div v-if="dataList.tree === null" style="width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;">暂无数据</div>
            </div>
            <div v-if="!overviewtoggle" class="area">
                <div class="area-wrapper" v-if="dataList.tree !== null">
                    <div class="wrapper-item">
                        <template>
                            <Card class="cards" v-for="item in dataList.tree" :key="item.id">
                                <div @click="open(item.code, item.name)" :class="item.contact === '0' ? 'baise' : item.contact === '1' ? 'blue' : 'yellow'">
                                    <!--                    <p style="height: 30px">-->
                                    <!--                      <span>10号</span>-->
                                    <!--                    </p>-->
                                    <p>
                                        <span>{{ item.name }}</span>
                                    </p>
                                </div>
                            </Card>
                        </template>
                    </div>
                </div>
                <div v-if="dataList.tree === null" style="width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;">暂无数据</div>
            </div>

            <template #bottom-operate>
                <Button type="primary" @click="closeFn1">关闭</Button>
            </template>
        </CompModal>
        <LiefengModalo class="listmodal" :value="listStatus" @input="changeListStatus">
            <template v-slot:title>
                <Button style="float:right;margin:10px 40px 0 0" type="primary" icon="ios-add" @click="addForm">新增居民</Button>
            </template>

            <template v-slot:contentarea>
                <Peoplelist :loading="loading" :tableData="tableData" :houseCode="houseCode" @getList="getList" :orgCode="communityCode"></Peoplelist>
            </template>
            <template v-slot:toolsbar>
                <Button type="primary" @click="closeFn2">关闭</Button>
            </template>
        </LiefengModalo>

        <CompModal title="房号居民" ref="people" @on-close="onClosePeople">
            <template #head-operate>
                <Button type="primary" icon="ios-add" @click="onDisplayForm">新增居民</Button>
            </template>
            <Peoplelist :loading="loading" :tableData="tableData" :houseCode="houseCode" @getList="getList" :orgCode="communityCode"></Peoplelist>
        </CompModal>

        <CompModal ref="add_model" title="新增居民基础数据" width="400px" @on-close="$refs.add_from.reset()">
            <Baseform ref="add_from" :code="communityCode" @on-submit="saveAddBase"></Baseform>
        </CompModal>
    </div>
</template>

<script>
import Axios from "axios"
import LiefengModal from "@/components/LiefengModal"
import LiefengModalo from "./children/LiefengModal"
import Peoplelist from "./children/peoplelist"
import Utils from "../jointly/utils/utils"
import CompModal from "../residentdatabase/components/CompModal.vue"
import Baseform from "../residentdatabase/children/baseform"

export default {
    props: {
        toggle: Boolean,
        nameover: String,
        value: Boolean,
        fullscreen: {
            default: Boolean,
        },
        closeFn1: Function,
        dataId: String,
        regionType: String,
        communityCode: String,
        overviewtoggle: {
            default: Boolean,
        },
    },
    watch: {
        value(v) {
            v && this.$refs.modal.display()
        },
    },
    data() {
        return {
            listStatus: false,
            tableData: [],
            houseCode: "",
            loading: false,

            dataList: {},
            // dataList: {},
            flag: true,
            nameList1: "",
            nameList2: "",
        }
    },
    methods: {
        save(obj, child) {
            this.nameList1 = obj.name
            this.nameList2 = child.name
            if (!this.$store.state.buildObj && !this.$store.state.buildChild) {
                this.$Message.loading({
                    content: "正在加载数据，请稍等...",
                    duration: 0,
                })
            }

            // this.$get('/syaa/pc/sy/user/online/home/onlineHomeRegionOverview', {
            //   orgCode: this.communityCode,
            //   regionId: child.id,
            //   regionType: child.regionType,
            //   oemCode: parent.vue.oemInfo.oemCode
            // }).then(res => {
            //   if (res.code == 200 && res.data) {
            //     this.$Message.destroy()
            //     this.dataList.contactCount = res.data.contactCount  //已入驻户代表数量
            //     this.dataList.settledCount = res.data.settledCount  //已入驻数量
            //     this.dataList.unSettledCount = res.data.unSettledCount  //未入驻数量
            //     this.dataList.tree = res.data.tree

            //   } else {
            //     this.$Message.destroy()
            //     this.$Message.error({
            //       background: true,
            //       content: res.desc
            //     });
            //   }
            // }).then(res => {  //建设区域总览总数统计
            this.$get("/syaa/pc/sy/user/online/home/onlineHomeRegionOverview", {
                orgCode: this.communityCode,
                regionId: child.id,
                regionType: child.regionType,
                oemCode: parent.vue.oemInfo.oemCode,
            })
                .then(res => {
                    if (res.code == 200 && res.data) {
                        this.$Message.destroy()
                        this.dataList = res.data
                    } else {
                        this.$Message.destroy()
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                    // })
                })
                .catch(err => {
                    this.$Message.destroy()
                    console.log(err)
                })
        },
        open(houseCode, name) {
            this.houseName = name
            this.$store.commit("changeBuildHouse", houseCode)
            this.$refs.people.display()
            this.getList(houseCode)
        },
        onClosePeople() {
            this.$store.commit("changeBuildHouse", "")
        },
        closeFn2() {
            this.listStatus = false
            this.$store.commit("changeBuildObj", {})
            this.$store.commit("changeBuildChild", {})
            this.$store.commit("changeBuildHouse", "")
        },

        getList(houseCode) {
            houseCode = houseCode || this.houseCode

            this.loading = true
            this.houseCode = houseCode

            const param = {
                page: 1,
                pageSize: 99999,
                hasHouseNum: true,
                communityCode: this.communityCode,
                houseNo: houseCode,
            }

            Axios({
                method: "post",
                url: `/syaa/api/syuser/pc/${this.type === "street" ? "streetUser" : "uaUserInfo"}/selectUserBasePage${Utils.jsonToParams(param)}`,
                data: [],
            })
                .then(res => {
                    this.loading = false
                    const data = res.data

                    if (data.code == 200 && data.dataList) {
                        this.tableData = data.dataList
                        this.tableData.map(item => {
                            item.updateStatus = 2
                        })
                        this.total = data.maxCount
                    } else {
                        this.$Message.error({
                            content: data.desc || data.msg,
                            background: true,
                        })
                    }
                })
                .catch(() => {
                    this.$Message.error({
                        content: "数据获取失败",
                        background: true,
                    })
                })

            //   this.$get('/syaa//api/syuser/pc/residentArchive/selectArchivesPage',{
            //     oemCode: parent.vue.oemInfo.oemCode,
            //     houseNo:houseCode,
            //     operCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
            //     orgCode: parent.vue.loginInfo.userinfo.orgCode,
            //     page: 1,
            //     pageSize:50,
            //   }).then( res => {
            //     if(res.code == 200){
            //       this.loading = false
            //       this.tableData = res.dataList
            //     }else{
            //       this.loading = false
            //       this.$Message.error({
            //           background: true,
            //           content: res.desc
            //         });
            //     }
            //   })
        },
        // addForm() {
        //     this.$router.push({ path: `/linehomeaddform?isEdit=false`, query: { nameover: this.$route.query.nameover, build: "true", communityCode: this.$route.query.communityCode, houseCode: this.houseCode } })
        // },

        onDisplayForm() {
            this.$refs.add_model.display()
            console.log("houseName: " + this.houseName)
            this.$refs.add_from.setFormData({
                addr: this.houseName,
                houseCode: this.houseCode,
            })
        },

        // 保存基础数据新增
        saveAddBase(evt) {
            const baseData = evt.value

            if (!baseData.userName) {
                return this.$Message.warning({
                    content: "请输入姓名",
                    background: true,
                })
            } else if (!baseData.addr) {
                return this.$Message.warning({
                    content: "请选择地址",
                    background: true,
                })
            } else if (baseData.idNumType == 1 && baseData.idNum && !Utils.verify("idNum", baseData.idNum)) {
                return this.$Message.warning({
                    content: "证件号码格式不正确",
                    background: true,
                })
            }

            this.$post("/syaa/api/syuser/pc/uaUserInfo/addUserBase", {
                ...baseData,
                communityCode: this.communityCode,
            })
                .then(res => {
                    if (res.code == 200) {
                        this.$Message.success({
                            content: "新增成功",
                            background: true,
                        })
                        this.getList()
                        this.$refs.add_model.close()
                    } else {
                        this.$Message.error({
                            content: res.desc,
                            background: true,
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.$Message.error({
                        content: "新增失败",
                        background: true,
                    })
                })
        },
    },
    created() {},
    beforeMount() {
        this.tableHeight = window.innerHeight - 200 + ""
    },

    components: {
        LiefengModal,
        LiefengModalo,
        Peoplelist,
        CompModal,
        Baseform,
    },
}
</script>

<style scoped lang="less">
// /deep/.ivu-modal-wrap{
//   .listmodal{
//     width: 70% !important;
//   }
// }

.baise {
    background: #ffffff;
}

.yellow {
    background: #ffb800;
}

.blue {
    background: #33cccc;
}

.icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
}

.iconfont {
    font-family: "iconfont" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    height: 100%;
}

.iconfont1 {
    width: 31px;
    height: 30px;
}

.white {
    background: #ffffff;
}

.yellow {
    background: #ffb800;
}

.blue {
    background: #33cccc;
}

.bot {
    margin: 0 10px;
}

.header {
    display: flex;

    /deep/ .ivu-card {
        margin: 0 20px;
    }

    > .cards {
        div {
            > p:nth-child(2) {
                color: red;
                font-size: 17px;
                font-weight: 800;
                margin: 10px 0;
            }

            > :nth-child(3) {
                color: #000;
                font-size: 15px;
                font-size: 20px;
            }
        }
    }
}

.illustrated {
    display: flex;
    margin: 10px 20px;
    box-sizing: border-box;
    background: #f2f2f2;

    > ul {
        box-sizing: border-box;
        display: flex;
        padding: 15px 0;
        font-size: 15px;
        display: flex;
        align-items: center;

        > li {
            display: flex;
            align-items: center;

            &:first-child {
                margin-right: 20px;
            }

            &:not(:first-child) {
                margin-right: 40px;
            }

            > span {
                margin-left: 8px;
                width: 38px;
                height: 34px;
                border: 1px solid #ccc;
                display: flex;

                > img {
                    width: 100%;
                    height: 100%;
                    vertical-align: middle;
                }
            }

            > .info {
                padding: 0 7px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                > span:first-child {
                    color: red;
                }
            }
        }
    }
}

.area {
    background: #f3f3f3;
    padding: 10px 0 0px 0;
    margin: 0 20px;
    display: flex;
    flex-direction: column;

    > .area-wrapper {
        display: flex;
        border-bottom: 1px dashed #bee580;
        margin-top: 10px;

        > span {
            font-weight: 800;
            font-size: 19px;
            padding: 0 0 0 10px;
            width: 180px;
        }

        > .wrapper-item {
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            min-height: 200px;

            > .cards {
                width: 126px;
                height: 89px;
                margin: 0 0 10px 10px;
                cursor: pointer;

                /deep/ .ivu-card-body {
                    padding: 0;
                    width: 100%;
                    height: 100%;

                    > div {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-evenly;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}

#house {
    display: flex;
    justify-content: space-between;

    > span {
        flex: 1;
    }
}
</style>
